var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getScsUrlPartsFromConfiguration, getVersionUrl } from './hooks';
import { fetchSVD, fetchVehicleRaw, getContextForDetailPage, getVehicleIdFromUrl, getVehicleRequestUrl, initializationPromises, HttpRequestError, } from './utils';
export function initializeFeatureApp(featureServices, config, additionalData = {}, alwaysReturnVehicle) {
    if (featureServices['s2:async-ssr-manager']) {
        return ssrInitialization(featureServices, config, additionalData);
    }
    if (featureServices['s2:serialized-state-manager']) {
        return csrAfterSSRInitialization(featureServices, config, additionalData);
    }
    return csrInitialization(featureServices, config, additionalData, alwaysReturnVehicle);
}
function ssrInitialization(featureServices, config, additionalData) {
    const asyncSsrManager = featureServices['s2:async-ssr-manager'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    const i18nService = featureServices['dbad:audi-i18n-service'];
    const pageInfoService = featureServices['page-info-service'];
    const context = getContext(featureServices, config);
    const vehicleId = getVehicleId(featureServices, config);
    const initialization = {
        loadingPromise: undefined,
        initializationType: 'ssr',
        contextId: context.contextId,
        vehicleId,
        additionalData: {},
    };
    const loadingPromise = initializationPromises([
        i18nService.setLocale(),
        i18nServiceLoadedPromise(i18nService),
        (() => __awaiter(this, void 0, void 0, function* () {
            let envConfig = null;
            if ('audi:envConfigService' in featureServices) {
                envConfig = yield featureServices['audi:envConfigService'].getConfiguration('vtp');
                initialization.envConfig = envConfig;
            }
            const vtpConfiguration = yield configurationServicePromise(featureServices);
            try {
                const { vehicle, svd } = yield getVehicle(vehicleId, featureServices);
                initialization.vehicleRaw = vehicle;
                initialization.svd = svd;
            }
            catch (e) {
                initialization.vehicleId = '';
                handleVehicleError(e, pageInfoService, vtpConfiguration);
            }
            initialization.additionalData = yield resolveAdditionalData(initialization, additionalData);
            if (serializedStateManager) {
                const serializedState = {
                    vehicleRaw: initialization.vehicleRaw,
                    envConfig: initialization.envConfig,
                    additionalData: initialization.additionalData,
                };
                serializedStateManager.register(() => JSON.stringify(serializedState));
            }
        }))(),
    ]);
    asyncSsrManager.scheduleRerender(loadingPromise.featureAppInitializationPromise);
    initialization.loadingPromise = loadingPromise;
    return initialization;
}
function handleVehicleError(error, pageInfoService, vtpConfiguration) {
    if (!(error instanceof HttpRequestError) || !pageInfoService) {
        return;
    }
    const httpRequestError = error;
    if (httpRequestError.statusCode !== 404) {
        return;
    }
    if (vtpConfiguration === null || vtpConfiguration === void 0 ? void 0 : vtpConfiguration.notFound) {
        const redirectUrl = typeof vtpConfiguration.notFound !== 'string'
            ? vtpConfiguration.notFound.path
            : vtpConfiguration.notFound;
        pageInfoService.setPageInfo({
            response: { status: 301, message: 'No Content Found', headers: { Location: redirectUrl } },
        });
    }
    else {
        pageInfoService.setPageInfo({
            response: { status: 404, message: 'No Content Found', headers: {} },
        });
    }
}
function csrAfterSSRInitialization(featureServices, config, additionalData) {
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    const context = getContext(featureServices, config);
    const serializedSate = serializedStateManager.getSerializedState()
        ? JSON.parse(serializedStateManager.getSerializedState())
        : undefined;
    if (!serializedSate) {
        return csrInitialization(featureServices, config, additionalData);
    }
    const loadingPromise = initializationPromises([
        featureServices['dbad:audi-i18n-service'].setLocale(),
        configurationServicePromise(featureServices),
    ]);
    const trackingService = featureServices['audi-tracking-service'];
    if (trackingService) {
        trackingService.featureAppName = __FEATURE_APP_NAME__;
    }
    return {
        loadingPromise,
        initializationType: 'csr',
        vehicleRaw: serializedSate.vehicleRaw,
        contextId: context.contextId,
        vehicleId: getVehicleId(featureServices, config),
        envConfig: serializedSate.envConfig,
        additionalData: serializedSate.additionalData,
    };
}
function csrInitialization(featureServices, config, additionalData, alwaysReturnVehicle) {
    const context = getContext(featureServices, config);
    const vehicleId = getVehicleId(featureServices, config);
    const initialization = {
        loadingPromise: undefined,
        initializationType: 'csr',
        contextId: context.contextId,
        vehicleId,
        additionalData: {},
    };
    const loadingPromise = initializationPromises([
        featureServices['dbad:audi-i18n-service'].setLocale(),
        (() => __awaiter(this, void 0, void 0, function* () {
            let envConfig = null;
            if ('audi:envConfigService' in featureServices) {
                envConfig = yield featureServices['audi:envConfigService'].getConfiguration('vtp');
                initialization.envConfig = envConfig;
            }
            initialization.additionalData = yield resolveAdditionalData(initialization, additionalData);
            if (alwaysReturnVehicle) {
                const returnedVehicleData = yield getVehicle(vehicleId, featureServices);
                const vehicle = returnedVehicleData === null || returnedVehicleData === void 0 ? void 0 : returnedVehicleData.vehicle;
                const svd = returnedVehicleData === null || returnedVehicleData === void 0 ? void 0 : returnedVehicleData.svd;
                if (vehicle) {
                    initialization.vehicleRaw = vehicle;
                    initialization.svd = svd;
                }
            }
        }))(),
        configurationServicePromise(featureServices),
    ]);
    const trackingService = featureServices['audi-tracking-service'];
    if (trackingService) {
        trackingService.featureAppName = __FEATURE_APP_NAME__;
    }
    initialization.loadingPromise = loadingPromise;
    return initialization;
}
function resolveAdditionalData(initialization, additionalData) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = {};
        yield Promise.all(Object.entries(additionalData).map(([key, resolveFuncion]) => {
            return resolveFuncion(initialization).then((resolved) => {
                result[key] = resolved;
            });
        }));
        return result;
    });
}
function i18nServiceLoadedPromise(i18nService) {
    return new Promise((resolve) => {
        if (i18nService) {
            i18nService.registerCallback(() => {
                resolve();
            });
        }
        else {
            resolve();
        }
    });
}
function configurationServicePromise(featureServices) {
    const configurationService = featureServices['vtp-configuration-service'];
    const configServicePromise = new Promise((resolve) => {
        if (configurationService) {
            configurationService.subscribeConfiguration((configuration) => resolve(configuration));
        }
        else {
            resolve(null);
        }
    });
    const timeoutPromise = new Promise((res) => setTimeout(() => res(null), 500));
    return Promise.race([configServicePromise, timeoutPromise]);
}
function getVehicleId(featureServices, config) {
    const serverRequest = featureServices['s2:server-request'];
    if (config === null || config === void 0 ? void 0 : config.vehicleId) {
        return config.vehicleId;
    }
    return serverRequest ? getVehicleIdFromUrl(serverRequest.url) : getVehicleIdFromUrl();
}
function getVehicle(vehicleId, featureServices) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        if (!vehicleId) {
            return null;
        }
        const vtpConfigService = (_a = featureServices['vtp-configuration-service']) !== null && _a !== void 0 ? _a : null;
        const envConfigService = (_b = featureServices['audi:envConfigService']) !== null && _b !== void 0 ? _b : null;
        const scsUrlParts = getScsUrlPartsFromConfiguration(vtpConfigService === null || vtpConfigService === void 0 ? void 0 : vtpConfigService.getConfiguration(), yield envConfigService.getConfiguration('vtp'));
        const versionUrl = getVersionUrl(scsUrlParts);
        const svd = yield fetchSVD(versionUrl);
        const vehicleUrl = getVehicleRequestUrl(scsUrlParts, vehicleId, svd);
        const vehicleRawResponse = yield fetchVehicleRaw(vehicleUrl);
        return { vehicle: vehicleRawResponse.vehicle, svd };
    });
}
function getContext(featureServices, config) {
    const serverRequest = featureServices['s2:server-request'];
    if ((config === null || config === void 0 ? void 0 : config.contextServiceUrl) && (config === null || config === void 0 ? void 0 : config.contextId)) {
        return { contextServiceUrl: config.contextServiceUrl, contextId: config.contextId };
    }
    if (serverRequest) {
        const host = serverRequest.headers.origin || serverRequest.headers.host;
        const url = new URL(`https://${host}${serverRequest.url}`);
        return getContextForDetailPage(url);
    }
    return getContextForDetailPage();
}
