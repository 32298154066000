import { ACTIONS } from '@oneaudi/stck-store';
import { Dispatch } from 'redux';
import { Campaign, CompleteVehicleEntry, EnvConfig } from '../../../interfaces';
import { isCampaignActive } from '../../../utils';
import { VTPConfiguration } from '@oneaudi/vtp-configuration-service';

export async function getActiveCampaign(
  fullVehicle: CompleteVehicleEntry,
  envConfig: EnvConfig,
  svd: string,
  configuration: VTPConfiguration
): Promise<Campaign> {
  const campaigns = await getActiveCampaigns(fullVehicle, envConfig, svd, configuration);
  return campaigns.reduce(isOlderCampaign, {} as Campaign);
}

export async function getActiveCampaigns(
  fullVehicle: CompleteVehicleEntry,
  envConfig: EnvConfig,
  svd: string,
  configuration: VTPConfiguration
): Promise<Campaign[]> {
  if (fullVehicle.basic.campaigns?.length) {
    const activeCampaigns: Campaign[] = await fetchCampaigns(envConfig, svd, configuration);
    if (!activeCampaigns.length) {
      return [];
    }

    const vehiclesActiveCampaigns = fullVehicle.basic.campaigns.filter((vehicleCampaign) => {
      return (
        vehicleCampaign.type &&
        vehicleCampaign.type === 'FINANCING' &&
        vehicleCampaign.typeDetail &&
        activeCampaigns.some(
          (activeCampaign) => activeCampaign.campaignId === vehicleCampaign.campaignId
        ) &&
        isCampaignActive(vehicleCampaign)
      );
    });
    return vehiclesActiveCampaigns;
  }
  return [];
}

export async function fetchCampaigns(
  envConfig: EnvConfig,
  svd: string,
  configuration?: VTPConfiguration
) {
  const url = `${envConfig.scs.baseUrl}${envConfig.scs.defaultApiVersion}/structure/campaigns/${configuration.scs.scsMarketPath}?svd=${svd}`;
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const resetFinancingCampaign = (
  dispatch: Dispatch<any>,
  fullVehicle: CompleteVehicleEntry
) => {
  dispatch(
    ACTIONS.FINANCING.addFinancingForVehicle({
      vehicleId: fullVehicle.basic.id,
      financing: { campaign: {} },
    })
  );
};

export const isOlderCampaign = (acc: Campaign, current: Campaign) => {
  if (!acc.startDate) {
    return current;
  }
  return acc.startDate < current.startDate ? acc : current;
};
