import React, { useEffect, useState } from 'react';
import { useServicesContext } from '../../../context';
import { useI18n } from '@oneaudi/i18n-context';
import { Checkbox } from '@audi/audi-ui-react-v2';
import { DealerDetails, Address, Distance, DealerListItemWrapper, DealerNameAndCount, StyledCountSpan, StyledCountText, StyledAddressIcon, StyledAddressText, StyledDistanceIcon, StyledDistanceText, } from '../styles/LocationFilter.styles';
import { getCounterForThisNumberOfMatches } from '../utils/utils';
import { FilterContext } from '../FilterContext';
export const DealersListItem = ({ active, count, isCheckedControlledByClickOnMarker, visibleIn, result, setSelectedDealer, }) => {
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const { mileageUnit } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const [isChecked, setIsChecked] = useState(false);
    const distanceText = useI18n({
        id: `nemo.sc.filter.locationfilter.distanceText`,
        defaultMessage: 'away',
    });
    const i18nCounterForThisNumberOfMatches = getCounterForThisNumberOfMatches(count);
    const handleChange = () => {
        setIsChecked(!isChecked);
        setSelectedDealer({ dealerId: result.dealerId, source: 'checkbox' });
    };
    useEffect(() => {
        setIsChecked(isCheckedControlledByClickOnMarker);
    }, [isCheckedControlledByClickOnMarker]);
    useEffect(() => {
        setIsChecked(active);
    }, [active]);
    return (React.createElement(DealerListItemWrapper, { status: result.status, lightMode: lightMode },
        React.createElement(DealerNameAndCount, null,
            React.createElement(Checkbox, { inputId: `checkbox_dealer_${result.name}_${visibleIn}`, checked: isChecked, disabled: count === 0, hideLabelOptional: true, spaceInlineStart: "s", spaceStackStart: "m", spaceStackEnd: "m", onChange: () => handleChange() }, result.name),
            React.createElement(StyledCountText, { disabled: count === 0 },
                "(",
                count,
                React.createElement(StyledCountSpan, null,
                    " ",
                    i18nCounterForThisNumberOfMatches),
                ")")),
        React.createElement(DealerDetails, { disabled: count === 0 },
            React.createElement(Address, null,
                React.createElement(StyledAddressIcon, { disabled: count === 0 }),
                React.createElement(StyledAddressText, { disabled: count === 0 },
                    result.street,
                    ", ",
                    result.region,
                    " ",
                    result.city)),
            (result === null || result === void 0 ? void 0 : result.distance) && (React.createElement(Distance, null,
                React.createElement(StyledDistanceIcon, { disabled: count === 0 }),
                React.createElement(StyledDistanceText, { disabled: count === 0 },
                    result.distance,
                    " ",
                    mileageUnit,
                    " ",
                    distanceText))))));
};
export default DealersListItem;
