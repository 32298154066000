var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getScsUrlPartsFromConfiguration } from '../../../../hooks';
import { resolveSeoFiltersFromUrl } from '../../filterService';
import { MarketType } from './seo.types';
import { handleSeo } from './seo';
import { generatePdpUrl } from '../../../../utils';
export const createCarlineSeoResolver = (pageInfoService, serverRequest) => {
    return (initialization) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (pageInfoService && ((_a = initialization === null || initialization === void 0 ? void 0 : initialization.vehicleRaw) === null || _a === void 0 ? void 0 : _a.basic)) {
            const pageInfo = pageInfoService.getPageInfo();
            const vehicleBasic = initialization.vehicleRaw.basic;
            let { canonicalUrl } = pageInfo.seo;
            if (serverRequest) {
                const baseUrl = serverRequest.url.split(':', 2).join(':');
                const canonicalPath = generatePdpUrl(vehicleBasic.modelYear, ((_b = vehicleBasic.model) === null || _b === void 0 ? void 0 : _b.description) || vehicleBasic.symbolicCarline.description, vehicleBasic.id);
                canonicalUrl = `${baseUrl}:/${canonicalPath}`;
            }
            pageInfoService.setPageInfo({
                seo: Object.assign(Object.assign({}, pageInfo.seo), { pageTitle: initialization.vehicleRaw.basic.symbolicCarline.description, canonicalUrl }),
            });
        }
    });
};
export const createFilterSeoResolver = (configurationService, envConfigService, serverRequest, pageInfoService, i18nService, localeService) => {
    return () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const vtpConfiguration = configurationService.getConfiguration();
        const envConfig = yield envConfigService.getConfiguration('vtp');
        const { isUsedCarMkt } = getScsUrlPartsFromConfiguration(vtpConfiguration, envConfig);
        const url = (serverRequest === null || serverRequest === void 0 ? void 0 : serverRequest.url) || (typeof window !== 'undefined' && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.href));
        const seoFilters = vtpConfiguration && envConfig
            ? yield resolveSeoFiltersFromUrl(vtpConfiguration, envConfig, url)
            : [];
        if (pageInfoService && seoFilters) {
            handleSeo(pageInfoService, i18nService, localeService, isUsedCarMkt ? MarketType.UC : MarketType.NC, seoFilters);
        }
        return seoFilters;
    });
};
