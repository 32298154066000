export function getSortParam(configurationService) {
    var _a, _b, _c, _d, _e;
    const configurationFA = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration();
    return ((_a = configurationFA === null || configurationFA === void 0 ? void 0 : configurationFA.sortParams) === null || _a === void 0 ? void 0 : _a.defaultOption)
        ? (_b = configurationFA === null || configurationFA === void 0 ? void 0 : configurationFA.sortParams) === null || _b === void 0 ? void 0 : _b.defaultOption
        : ((_d = (_c = configurationFA === null || configurationFA === void 0 ? void 0 : configurationFA.sortParams) === null || _c === void 0 ? void 0 : _c.options) === null || _d === void 0 ? void 0 : _d.length)
            ? (_e = configurationFA === null || configurationFA === void 0 ? void 0 : configurationFA.sortParams) === null || _e === void 0 ? void 0 : _e.options[0]
            : 'prices.retail:asc';
}
export const findCategoryInFilterConfig = (configuredFilter, seachCriterium) => {
    return configuredFilter === null || configuredFilter === void 0 ? void 0 : configuredFilter.filterGroups.some((filterGroup) => (filterGroup === null || filterGroup === void 0 ? void 0 : filterGroup.filter) === (seachCriterium === null || seachCriterium === void 0 ? void 0 : seachCriterium.split(':')[0]));
};
export const formatEquipmentFilterData = (mainCategory, introCategory, subCategories) => {
    if (!introCategory || !subCategories || subCategories.length === 0) {
        return [];
    }
    const formattedSubCategories = [];
    subCategories.forEach((subCategory) => {
        const filterGroupsObj = {
            filterGroups: [],
        };
        Object.values(subCategory.fields).forEach((field) => {
            const filterGroup = { filterGroup: field };
            filterGroupsObj.filterGroups.push(filterGroup);
        });
        formattedSubCategories.push(filterGroupsObj);
    });
    const formattedIntroCategory = [
        { filterGroups: [{ filterGroup: introCategory }] },
    ];
    const allEquipmentFilterCategoriesInSameFormat = formattedIntroCategory.concat(formattedSubCategories);
    const equipmentFilterWithCategoryNames = allEquipmentFilterCategoriesInSameFormat === null || allEquipmentFilterCategoriesInSameFormat === void 0 ? void 0 : allEquipmentFilterCategoriesInSameFormat.map((eqFilterCategory) => {
        return Object.assign(Object.assign({}, eqFilterCategory), { filterCategory: mainCategory, isEquipmentFilterCategory: true, isEquipmentFilter: false });
    });
    return equipmentFilterWithCategoryNames;
};
export const formatFilterCategories = (filterCategories) => {
    const filterGroup = 'filterGroup';
    const layoutWidth = 'layoutWidth';
    const formattedFilterCategories = filterCategories.map((item) => {
        const filterCategoryObj = { filterCategory: '', filterGroups: [] };
        filterCategoryObj.filterCategory = item.fields.filterCategory;
        const { fields } = item;
        for (const [key, value] of Object.entries(fields)) {
            const filterGroupKey = key.startsWith(filterGroup);
            if (filterGroupKey) {
                const filterGroupObj = {};
                filterGroupObj.filterGroup = value;
                const itemOrder = key.replace(filterGroup, '');
                const correspondingLayoutWidth = fields[`${layoutWidth}${itemOrder}`];
                filterGroupObj.layoutWidth = correspondingLayoutWidth || '100';
                filterCategoryObj.filterGroups.push(filterGroupObj);
            }
        }
        return filterCategoryObj;
    });
    return formattedFilterCategories;
};
export const addEquipmentFilterToFilterGroups = (equipmentFilter, filterCategories) => {
    const findIndexOfEqFilterCategory = (category) => {
        var _a;
        return ((_a = category.filterGroups[0]) === null || _a === void 0 ? void 0 : _a.filterGroup) === 'equipment.equipment-block';
    };
    const indexOfEqFilterCategory = filterCategories.findIndex(findIndexOfEqFilterCategory);
    if (indexOfEqFilterCategory === -1) {
        return filterCategories;
    }
    if (equipmentFilter.length === 0 || !equipmentFilter) {
        console.log('🚨 FilterFA: To display the equipmentFilter please configure the equipmentFilterBlock');
        filterCategories.splice(indexOfEqFilterCategory, 1);
        return filterCategories;
    }
    const collectAllEqFilterGroups = () => {
        const allFilterGroupsThatBelongInTheEqFilter = [];
        equipmentFilter === null || equipmentFilter === void 0 ? void 0 : equipmentFilter.map((eqFilterCategory) => {
            eqFilterCategory.filterGroups.map((eqFilterGroup) => {
                allFilterGroupsThatBelongInTheEqFilter.push(eqFilterGroup);
            });
        });
        return allFilterGroupsThatBelongInTheEqFilter;
    };
    const allEqFilterFilterGroups = Object.assign(Object.assign({}, filterCategories[indexOfEqFilterCategory]), { isEquipmentFilter: true, filterGroups: collectAllEqFilterGroups() });
    const filterCategoriesWithFormattedEqFilter = Array.from(filterCategories);
    filterCategoriesWithFormattedEqFilter[indexOfEqFilterCategory] = allEqFilterFilterGroups;
    return filterCategoriesWithFormattedEqFilter;
};
export const findSelectedFilterCategory = (filterCategory, filterGroup) => {
    return (filterCategory.filterGroups.some((item) => item.filter === filterGroup) &&
        filterCategory.filterCategory);
};
export const isCampaignValid = (start, end) => {
    if (!start || !end)
        return false;
    const now = Date.now();
    return start <= now && now <= end;
};
export const updateFiltersWithCampaigns = (filterResponse, campaignData) => {
    var _a, _b;
    if (!filterResponse || !campaignData)
        return null;
    const filteredCampaigns = {};
    const configuredCampaignFilters = (_b = (_a = filterResponse.groups) === null || _a === void 0 ? void 0 : _a.campaigns) === null || _b === void 0 ? void 0 : _b.members;
    console.log('### configuredCampaignFilters', configuredCampaignFilters);
    if (!configuredCampaignFilters) {
        return filterResponse;
    }
    const campaignIds = Object.keys(configuredCampaignFilters);
    campaignIds.forEach((id) => {
        const campaignDetails = campaignData.find((campaign) => id.endsWith(campaign.campaignId));
        const isValid = campaignDetails && isCampaignValid(campaignDetails === null || campaignDetails === void 0 ? void 0 : campaignDetails.startDate, campaignDetails === null || campaignDetails === void 0 ? void 0 : campaignDetails.endDate);
        console.log('### CD: ', campaignDetails, !!campaignDetails, isValid);
        if (isValid) {
            filteredCampaigns[id] = configuredCampaignFilters[id];
        }
    });
    return Object.assign(Object.assign({}, filterResponse), { groups: Object.assign(Object.assign({}, filterResponse.groups), { campaigns: { members: filteredCampaigns } }) });
};
