var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ACTIONS, SELECTORS, ExpiringStorage } from '@oneaudi/stck-store';
import { httpRequest } from '../../utils';
import { getScsUrlPartsFromConfiguration } from '../../hooks';
import { getSortParam } from './utils/filterHelper';
import { getRandomNumber, shuffleArray } from './utils/utils';
const hashRegexp = {
    filter: /[#|&]{1}(filter=)[^&]+/gi,
    preset: /[#|&]{1}(preset=)[^&]+/gi,
    keepFilters: /[#|&]{1}(keepFilters=)[^&]+/gi,
};
const cleanHashRegEx = /[#|&]{1}(filter=|preset=|keepFilters=|altSort=|action=|searchid=|pid=|csref=)[^&]+/gi;
export const resolveSeoFiltersFromUrl = (vtpConfiguration, envConfig, url) => __awaiter(void 0, void 0, void 0, function* () {
    const { scsBaseUrl, marketPath } = getScsUrlPartsFromConfiguration(vtpConfiguration, envConfig);
    const pathname = (url === null || url === void 0 ? void 0 : url.includes('/:/')) ? url.split('/:/')[1] : undefined;
    if (pathname) {
        try {
            const scsUrl = `${scsBaseUrl}v1/structure/filterKeysToValues/${marketPath}?values=${pathname.split('/').join('%2C')}`;
            const filterizedSeoSlugs = yield httpRequest(scsUrl);
            if (filterizedSeoSlugs.parsedBody) {
                return filterizedSeoSlugs.parsedBody.filterToValues;
            }
        }
        catch (error) {
            console.log('Could not fetch seo slugs: ', error.message);
        }
    }
    return [];
});
export function entryFilters(store, configurationService, dispatch, vtpConfiguration, envConfig, svd, seoFilters) {
    return __awaiter(this, void 0, void 0, function* () {
        if (typeof window === 'undefined') {
            return null;
        }
        const entryHash = window.location.hash;
        const storedFilters = SELECTORS.FILTERS.getPersistedFiltersMaps(store.state);
        const storedPresets = SELECTORS.FILTERS.getPersistedPresetsMap(store.state);
        const parsedObjectsFromHash = extractFiltersAndPresetsFromHash(entryHash);
        let temporaryStoredFilters = new Map();
        const keepFilters = new Map([...parsedObjectsFromHash.keepFilters].filter(([key]) => key));
        if (keepFilters.size) {
            temporaryStoredFilters = new Map(storedFilters);
            const temporaryKeys = Array.from(temporaryStoredFilters.keys());
            const filteredKeys = temporaryKeys.filter((key) => {
                return Array.from(keepFilters.keys()).some((keepKey) => key.startsWith(keepKey));
            });
            const filteredTemporaryStoredFilters = new Map(filteredKeys.map((key) => [key, temporaryStoredFilters.get(key)]));
            temporaryStoredFilters.clear();
            filteredTemporaryStoredFilters.forEach((value, key) => temporaryStoredFilters.set(key, value));
        }
        const seoFilterSlugs = seoFilters.reduce((slugs, seoFilter) => {
            if (seoFilter.filter) {
                const seoFilterDTO = {
                    id: seoFilter.filter,
                    active: true,
                    preset: false,
                };
                slugs.set(seoFilterDTO.id, seoFilterDTO);
            }
            return slugs;
        }, new Map());
        if (parsedObjectsFromHash.filters.size) {
            storedFilters.clear();
        }
        if (seoFilterSlugs && seoFilterSlugs.size) {
            storedFilters.clear();
        }
        removePreviousUniquePresets(storedPresets, parsedObjectsFromHash.presets);
        const temporaryFilters = keepFilters.size ? [...temporaryStoredFilters] : [];
        const actualFilters = new Map([
            ...storedFilters,
            ...parsedObjectsFromHash.filters,
            ...seoFilterSlugs,
            ...temporaryFilters,
        ]);
        const actualPresets = new Map([...storedPresets, ...parsedObjectsFromHash.presets]);
        setDistanceSorting(actualFilters, actualPresets, dispatch);
        return initWithFilters(actualFilters, actualPresets, store, configurationService, dispatch, vtpConfiguration, envConfig, svd);
    });
}
function initWithFilters(filters, presets, store, configurationService, dispatch, vtpConfiguration, envConfig, svd) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(ACTIONS.UI.resetResultsCount());
        const allActiveFiltersDataStrings = createFiltersDataString(filters);
        const allActivePresetsDataStrings = createPresetsDataString(presets);
        const filterResponse = yield updateFiltersAndResults(svd, allActiveFiltersDataStrings, allActivePresetsDataStrings, configurationService, store, dispatch, vtpConfiguration, envConfig);
        dispatch(ACTIONS.UI.setLoader({ state: false, feature: 'filter' }));
        return filterResponse;
    });
}
export function createFiltersDataString(filters) {
    return [...filters.values()].map((filter) => {
        let filterDataString = `${filter.id}`;
        if (filter.values && filter.values.length > 1 && filter.values.length < 5) {
            filterDataString += `:${filter.values[0]}_${filter.values[1]}`;
        }
        if (filter.values && filter.values.length >= 5) {
            filterDataString = 'geo:';
            for (let i = 0; i < filter.values.length; i++) {
                filterDataString += `${filter.values[i]}_`;
            }
            filterDataString = filterDataString.slice(0, -1);
        }
        return filterDataString;
    });
}
export function createPresetsDataString(presets) {
    return [...presets.values()].map((preset) => {
        let presetDataString = `${preset.id}`;
        if (preset.values) {
            presetDataString += ':';
            if (preset.values) {
                preset.values.forEach((presetValue, index) => {
                    if (preset.values && index !== preset.values.length - 1) {
                        presetDataString += `${presetValue}_`;
                    }
                    else {
                        presetDataString += `${presetValue}`;
                    }
                });
            }
        }
        return presetDataString;
    });
}
function setDistanceSorting(actualFilters, actualPresets, dispatch) {
    const distanceSorting = 'byDistance:asc';
    const hasActiveGeoFilter = actualFilters.has('geo');
    const hasActiveGeoPreset = actualPresets.has('geo');
    if (!!distanceSorting && (hasActiveGeoFilter || hasActiveGeoPreset)) {
        dispatch(ACTIONS.SORTING.setSorting({
            sorting: { results: distanceSorting, favorites: distanceSorting },
        }));
    }
}
function removePreviousUniquePresets(persistedPresets = new Map(), parsedPresetsFromHash = new Map()) {
    const uniquePresetPatterns = ['geo:'];
    const uniqueConflictingPresetPatterns = [
        'dealer.',
        'dealer-investor-code.',
        'dealer-group-name.',
    ];
    uniquePresetPatterns.forEach((uniquePresetPattern) => {
        const persistedPreset = [...persistedPresets.keys()].find((preset) => preset.includes(uniquePresetPattern));
        const parsedFromHashPreset = [...parsedPresetsFromHash.keys()].find((preset) => preset.includes(uniquePresetPattern));
        if (persistedPreset && parsedFromHashPreset) {
            persistedPresets.delete(persistedPreset);
        }
    });
    const containsConflictingKeys = [...parsedPresetsFromHash.keys()].some((preset) => uniqueConflictingPresetPatterns.some((key) => preset.includes(key)));
    if (containsConflictingKeys) {
        const keys = [...persistedPresets.keys()].filter((preset) => uniqueConflictingPresetPatterns.some((key) => preset.includes(key)));
        keys.forEach((key) => persistedPresets.delete(key));
    }
}
function extractFiltersAndPresetsFromHash(hash) {
    let filters = new Map();
    let presets = new Map();
    let keepFilters = new Map();
    if (hash) {
        const decodedHash = decodeURIComponent(hash);
        const filtersHash = getFilterHash(decodedHash, 'filter');
        if (filtersHash !== null) {
            filters = parseFilterStringToMap(filtersHash, false);
        }
        const presetsHash = getFilterHash(decodedHash, 'preset');
        if (presetsHash !== null) {
            presets = parseFilterStringToMap(presetsHash, true);
        }
        const keepFilterHash = getFilterHash(decodedHash, 'keepFilters');
        if (keepFilterHash !== null) {
            keepFilters = parseFilterStringToMap(keepFilterHash, true);
        }
    }
    return { filters, presets, keepFilters };
}
export function cleanupFilterURLHash() {
    const { hash } = window.location;
    const hasFilterHashMatch = hash.match(cleanHashRegEx);
    if (hasFilterHashMatch && hasFilterHashMatch.length) {
        if (window.history !== undefined && window.history.pushState !== undefined) {
            window.history.pushState('', document.title, window.location.pathname + window.location.search);
        }
    }
}
function parseFilterStringToMap(filterString, preset = false) {
    const currentFiltersMap = new Map();
    const filtersRawArray = filterString.split(',');
    filtersRawArray.forEach((filter) => {
        const filterDTO = initFromFilterString(filter, true, preset);
        if (filterDTO) {
            currentFiltersMap.set(filterDTO.id, filterDTO);
        }
    });
    return currentFiltersMap;
}
function getFilterHash(hash, type) {
    const regex = hashRegexp[type];
    const match = hash.match(regex);
    if (match && match.length) {
        return match[0].replace(/[#|&](filter=|preset=|keepFilters=)/gi, '');
    }
    return null;
}
function initFromFilterString(filterString_ = '', active_ = false, preset_ = false, group_) {
    const splittedFilter = filterString_.split(':');
    const id = splittedFilter.shift();
    let values;
    if (splittedFilter.length) {
        values = splittedFilter.shift().split('_');
    }
    return {
        id: id,
        active: active_,
        values,
        preset: preset_,
        group: group_,
    };
}
export function updateFiltersAndResults(svd, activeFilters, activePresets, configurationService, store, dispatch, vtpConfiguration, envConfig) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(ACTIONS.UI.setLoader({ state: true, feature: 'filter' }));
        const scsUrlParts = getScsUrlPartsFromConfiguration(vtpConfiguration, envConfig);
        let sortParam;
        const storedSortValue = SELECTORS.SORTING.getSortingStateRaw(store.state);
        if (storedSortValue.results) {
            sortParam = storedSortValue.results;
        }
        if (!sortParam) {
            sortParam = getSortParam(configurationService);
        }
        const hasGeoFilter = activeFilters.some((filter) => filter.startsWith('geo'));
        if (hasGeoFilter) {
            sortParam = 'byDistance:asc';
        }
        let url = `${scsUrlParts.scsBaseUrl}${scsUrlParts.versionsMapping.filter || scsUrlParts.defaultVersion}/search/filter/${scsUrlParts.marketPath}?svd=${svd}&size=12&sort=${encodeURIComponent(sortParam)}`;
        if (activeFilters.length > 0)
            url += `&filter=${encodeURIComponent(activeFilters.join(','))}`;
        if (activePresets.length > 0)
            url += `&preset=${encodeURIComponent(activePresets.join(','))}`;
        const filterResponse = yield httpRequest(url);
        dispatch(ACTIONS.FILTERS.handleFilterResponse({ response: filterResponse.parsedBody }));
        return filterResponse.parsedBody;
    });
}
export const getRangeScope = (scsBaseUrl, scsMarketPath, versionString, svd) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const rangeScope = (yield httpRequest(`${scsBaseUrl}${versionString}/structure/ranges/${scsMarketPath}?svd=${svd}`));
    const rangeScopesArray = [];
    Object.entries((_a = rangeScope === null || rangeScope === void 0 ? void 0 : rangeScope.parsedBody) === null || _a === void 0 ? void 0 : _a.ranges).forEach((key) => {
        rangeScopesArray.push({ rangeFilter: key[0], rangeScope: key[1] });
    });
    return rangeScopesArray;
});
export const getCarlineNamesAndGroupsStructure = (scsBaseUrl, scsMarketPath, versionString, svd) => __awaiter(void 0, void 0, void 0, function* () {
    var _b, _c, _d;
    const carlineNamesAndGroupsStructure = (yield httpRequest(`${scsBaseUrl}${versionString}/structure/carlines/${scsMarketPath}?svd=${svd}`));
    const carlineNamesAndGroupsStructureObject = {
        carlineNames: (_b = carlineNamesAndGroupsStructure === null || carlineNamesAndGroupsStructure === void 0 ? void 0 : carlineNamesAndGroupsStructure.parsedBody) === null || _b === void 0 ? void 0 : _b.carlines,
        carlineGroupsStructure: (_d = (_c = carlineNamesAndGroupsStructure === null || carlineNamesAndGroupsStructure === void 0 ? void 0 : carlineNamesAndGroupsStructure.parsedBody) === null || _c === void 0 ? void 0 : _c.structures) === null || _d === void 0 ? void 0 : _d.carlinegroups,
    };
    return carlineNamesAndGroupsStructureObject;
});
export const addWebpParameter = (url) => {
    if (url.includes('?')) {
        if (url.includes('fmt=webp-alpha')) {
            return url;
        }
        return `${url}&fmt=webp-alpha`;
    }
    return `${url}?fmt=webp-alpha`;
};
export const prepareModelFilterData = (carlineGroupGraphQLPhotos, carlineNamesAndGroupsStructure, carlineGroupAEMConfigPhotos) => {
    const carlineGroupPhotos = [];
    Object.keys(carlineNamesAndGroupsStructure.carlineGroupsStructure).map((key) => {
        var _a, _b, _c;
        let carlineGroupPhotoFromAEMConfig;
        carlineGroupAEMConfigPhotos === null || carlineGroupAEMConfigPhotos === void 0 ? void 0 : carlineGroupAEMConfigPhotos.forEach((item) => {
            if (item.fields.carlineGroup === key) {
                carlineGroupPhotoFromAEMConfig = addWebpParameter(item.fields.carlineGroupPhoto.path);
            }
        });
        const carlineGroupPhotoFromGraphQL = (_c = (_b = (_a = carlineGroupGraphQLPhotos === null || carlineGroupGraphQLPhotos === void 0 ? void 0 : carlineGroupGraphQLPhotos.find((elem) => elem.id === key)) === null || _a === void 0 ? void 0 : _a.carlines[0]) === null || _b === void 0 ? void 0 : _b.exteriorViews) === null || _c === void 0 ? void 0 : _c.side;
        const carlineGroupPhoto = carlineGroupPhotoFromAEMConfig || carlineGroupPhotoFromGraphQL;
        return carlineGroupPhotos.push({ carlineGroup: key, carlineGroupPhoto });
    });
    return {
        carlineNames: carlineNamesAndGroupsStructure.carlineNames,
        carlineGroupsStructure: carlineNamesAndGroupsStructure.carlineGroupsStructure,
        carlineGroupPhotos,
    };
};
export function getDealers(vtpConfiguration, envConfig, lat, lon, radius) {
    return __awaiter(this, void 0, void 0, function* () {
        const scsUrlParts = getScsUrlPartsFromConfiguration(vtpConfiguration, envConfig);
        let url;
        const isInitialDealersSearch = !lat && !lon && !radius;
        if (lat && lon && radius) {
            url = `${scsUrlParts.scsBaseUrl}${scsUrlParts.versionsMapping.filter || scsUrlParts.defaultVersion}/search/dealers/${scsUrlParts.marketPath}?filter=geo:${lat}_${lon}_${radius}_km_NB&from=0&size=2000&sort=DISTANCE:ASC`;
        }
        else {
            url = `${scsUrlParts.scsBaseUrl}${scsUrlParts.versionsMapping.filter || scsUrlParts.defaultVersion}/search/dealers/${scsUrlParts.marketPath}?from=0&size=2000`;
        }
        const dealerResponse = yield httpRequest(url);
        if (isInitialDealersSearch) {
            const localStorageLocationSearchKey = `VTP_${scsUrlParts.marketPath}_locationSearch--seed`;
            const localStorage = new ExpiringStorage(localStorageLocationSearchKey, 30);
            let seedFromLocalStorage = localStorage.readEntry();
            if (!seedFromLocalStorage) {
                const randomSeed = getRandomNumber();
                seedFromLocalStorage = randomSeed;
                localStorage.writeEntry(randomSeed);
            }
            const dealerParsedBody = dealerResponse.parsedBody;
            const dealerResults = [...dealerParsedBody.results];
            const shuffledDealerResults = shuffleArray(dealerResults, seedFromLocalStorage);
            dealerParsedBody.results = shuffledDealerResults;
        }
        return dealerResponse.parsedBody;
    });
}
