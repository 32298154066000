import { useMemo } from 'react';
import { useServicesContext } from '../../context';
export const useScsUrlParts = () => {
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const { versionsMapping, marketPath, language, market, scsBaseUrl, defaultVersion, isUsedCarMkt, } = getScsUrlPartsFromConfiguration(getConfiguration(), getEnvironmentConfig());
    return useMemo(() => ({
        versionsMapping,
        marketPath,
        language,
        market,
        scsBaseUrl,
        defaultVersion,
        isUsedCarMkt,
    }), [defaultVersion, language, market, marketPath, scsBaseUrl, versionsMapping, isUsedCarMkt]);
};
export const getScsUrlPartsFromConfiguration = (configuration, envConfig) => {
    var _a;
    let versionsMapping = null;
    if (envConfig) {
        versionsMapping = envConfig.scs.apiVersionMapping;
    }
    const defaultVersion = envConfig === null || envConfig === void 0 ? void 0 : envConfig.scs.defaultApiVersion;
    const marketPath = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.scs) === null || _a === void 0 ? void 0 : _a.scsMarketPath;
    const [language, market] = marketPath ? marketPath.split('/') : [];
    const scsBaseUrl = envConfig === null || envConfig === void 0 ? void 0 : envConfig.scs.baseUrl;
    const isUsedCarMkt = marketPath === null || marketPath === void 0 ? void 0 : marketPath.includes('uc');
    return {
        versionsMapping,
        marketPath,
        language,
        market,
        scsBaseUrl,
        defaultVersion,
        isUsedCarMkt,
    };
};
